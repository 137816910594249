import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Link from '../Link';

import { BlogCardStyled, BlogContent } from './blog-card.styled';

function BlogCard({ date, title, description, link, image }) {
  return (
    <BlogCardStyled className="group">
      <Link to={link} className="card-link">
        {image && <GatsbyImage image={getImage(image)} alt={title} />}
        <BlogContent>
          <div className="top-content">
            <h6>{date}</h6>
            <h3 className="h3">{title}</h3>
            {description && <p className="p">{description}</p>}
          </div>
          <span className="more">Read more</span>
        </BlogContent>
      </Link>
    </BlogCardStyled>
  );
}

export default BlogCard;

BlogCard.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};
