import tw, { styled } from 'twin.macro';

export const CommonBannerStyled = styled.div`
  ${tw`text-base flex-col flex justify-center items-center`};
  ${tw`bg-primary-dark`};
  min-height: 480px;

  .ves-container {
    ${tw`flex-col flex items-center justify-center w-full`};
  }

  .banner-titles-wrapper {
    ${tw`flex-col flex justify-center items-center flex-grow md:flex-grow-0 mt-16`};
  }

  .no-banner-image {
    .banner-titles-wrapper {
      ${tw`mt-32`};
    }
  }

  .common-banner {
    ${tw`w-full h-full flex flex-1`};
  }

  h1 {
    ${tw`px-2 text-white inline relative mb-3 text-center`};
    max-width: 820px;
  }

  h4 {
    ${tw`px-2 text-white inline relative font-normal text-center`};
    max-width: 720px;
  }
`;
