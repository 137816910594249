import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import { CommonBannerStyled } from './commonBanner.styled';

export default function CommonBanner(props) {
  const { title, subtitle, banner, noBannerImage } = props;
  const image = getImage(banner);
  const bgImage = convertToBgImage(image);

  return (
    <CommonBannerStyled>
      <BackgroundImage
        Tag="section"
        className={`common-banner large ${noBannerImage && 'no-banner-image'}`}
        {...bgImage}
        keepStatic
        fadeIn
        backgroundColor="#030e3f">
        <div className="ves-container">
          <div className="banner-titles-wrapper">
            {title && <h1 className="h1">{title}</h1>}
            {subtitle && <h4 className="h4">{subtitle}</h4>}
          </div>
        </div>
      </BackgroundImage>
    </CommonBannerStyled>
  );
}

CommonBanner.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  banner: PropTypes.shape({
    base64: PropTypes.string,
    src: PropTypes.string,
  }),
  noBannerImage: PropTypes.bool,
};
