import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';

import Layout from '../layouts/layout';
import CommonBanner from '../components/commonBanner';
import BlogCard from '../components/blog-card';
import Link from '../components/Link';
import MetaTags from '../components/SiteMeta';

export const BlogListStyled = styled.section`
  ${tw`block relative`};
`;

export const SectionHeaderStyled = styled.div`
  ${tw`flex flex-col md:flex-row md:justify-between md:items-center relative items-center text-center mb-7`};

  h2 {
    ${tw`mb-2 text-primary-dark`}
  }
`;

export const FeaturedArticleSection = styled.div`
  ${tw`relative`};

  .featured-article-img-col {
    .gatsby-image-wrapper {
      ${tw`w-full h-full`};
    }
  }

  .featured-article-content-col {
    h6 {
      ${tw`uppercase text-gray-darker font-bold mb-3`};
    }

    h3 {
      ${tw`text-primary-dark text-2xl lg:text-3xl font-bold mb-5`};
    }

    p {
      ${tw`lg:text-xl text-gray-dark mb-7 lg:mb-10 lg:leading-8`};
    }

    .more {
      ${tw`uppercase font-bold`};
      letter-spacing: 1.6px;
    }
  }
`;

// markup
export default class BlogsPage extends React.Component {
  constructor(props) {
    super(props);
    const postsToShow = 6;
    this.state = {
      postsToShow,
    };
  }

  render() {
    const allPosts = this.props.data.allWpCaseStudy?.nodes;
    console.log('allPosts', allPosts);
    const categories = this.props.data.categories?.nodes;
    const index = this.state.postsToShow;
    const { postsToShow } = this.state;
    const { acfGlobalContent } = this.props.data?.page?.nodes[0];
    const { acfCaseStudiesListing } = this.props.data?.page?.nodes[0];
    const featuredArticles = [];
    allPosts.map((item) =>
      item?.acfCaseStudies?.featuredArticle ? featuredArticles?.push(item) : '',
    );

    return (
      <Layout preFooterContent={acfGlobalContent} headerSpecificClass="blog-listing">
        <MetaTags
          title={acfGlobalContent.metaTitle}
          description={acfGlobalContent.metaDescription}
          image={acfGlobalContent.metaOgImage?.sourceUrl}
        />
        <main>
          <CommonBanner
            title={acfCaseStudiesListing?.caseStudiesPageTitle}
            subtitle={acfCaseStudiesListing?.caseStudiesSubtitle}
            noBannerImage
          />

          {featuredArticles?.length !== 0 && (
            <FeaturedArticleSection className="featured-article-section ves-section">
              <div className="ves-container">
                <div className="featured-article" key={featuredArticles[0].id}>
                  <Link
                    to={featuredArticles[0].uri}
                    className="grid grid-cols-1 md:grid-cols-12 md:gap-12">
                    <div className="featured-article-img-col md:col-span-6">
                      {featuredArticles[0].featuredImage && (
                        <GatsbyImage
                          image={getImage(featuredArticles[0].featuredImage?.node.localFile)}
                          alt={featuredArticles[0].title}
                        />
                      )}
                    </div>
                    <div className="featured-article-content-col md:col-span-6">
                      <h6>{featuredArticles[0].date}</h6>
                      <h3 className="h3">{featuredArticles[0].title}</h3>
                      <p className="p">{featuredArticles[0].acfCaseStudies.briefDescription}</p>
                      <span className="more">Read more</span>
                    </div>
                  </Link>
                </div>
              </div>
            </FeaturedArticleSection>
          )}

          <BlogListStyled>
            <div className="ves-container">
              <SectionHeaderStyled>
                <h2 className="h2">Case Studies</h2>
              </SectionHeaderStyled>

              <div className="grid  md:grid-cols-3 gap-4 md:gap-y-16">
                {allPosts?.length > 0
                  && allPosts
                      ?.slice(0, index)
                      ?.map((item) => (
                        <BlogCard
                          key={uuidv4()}
                          date={item?.date}
                          title={item?.title}
                          description={item?.acfCaseStudies.briefDescription}
                          link={item?.uri}
                          image={item?.featuredImage?.node.localFile}
                        />
                      ))}
              </div>

              {allPosts?.length > 0
                && postsToShow < allPosts?.length && (
                    <div className="load-more-wrap mt-12">
                      <button
                        type="button"
                        className="btn btn-primary m-auto outline-none"
                        onClick={() => {
                          this.setState({
                            postsToShow: postsToShow + 6,
                          });
                        }}>
                        Load More
                      </button>
                    </div>
                  )}
            </div>
          </BlogListStyled>
        </main>
      </Layout>
    );
  }
}

export const query = graphql`
  query CaseStudiesListing {
    allWpCaseStudy {
      nodes {
        title
        acfCaseStudies {
          alternativeTitle
          briefDescription
          featuredArticle
        }
        uri
        id
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 500, height: 252, placeholder: BLURRED, quality: 90)
              }
            }
          }
        }
      }
    }
    page: allWpPage(filter: { slug: { eq: "case-studies" } }) {
      nodes {
        acfCaseStudiesListing {
          caseStudiesPageTitle
          caseStudiesSubtitle
        }
        acfGlobalContent {
          prefooterTitle
          prefooterButtonText
          prefooterButtonLink {
            url
          }
          metaTitle
          metaDescription
          metaOgImage {
            sourceUrl
          }
        }
      }
    }
  }
`;

BlogsPage.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.any),
    }),
  }).isRequired,
};
