import tw, { styled } from 'twin.macro';

export const BlogCardStyled = styled.div`
  ${tw`flex flex-col relative shadow-none hover:shadow-2xl transition transition-all ease-in-out duration-200 border border-solid border-gray-light hover:border-transparent `}

  .card-link {
    ${tw`h-full flex flex-col `}
  }

  img {
    ${tw`transform scale-100 transition transition-all ease-in-out duration-500`}
  }
  &:hover {
    img {
      ${tw`scale-125`}
    }
  }
`;

export const BlogContent = styled.div`
  ${tw`pt-8 px-9 pb-9 mb-12 md:mb-0 md:flex-1 flex flex-col md:justify-between`}
  border-top: none;
  h6 {
    ${tw`font-bold text-primary-dark mb-3`}
  }
  h3 {
    ${tw`text-primary-dark mb-7`}
  }
  p {
    ${tw`leading-7 text-gray`}
  }
  .more {
    ${tw`flex self-start leading-3 text-primary-dark tracking-widest uppercase font-bold mt-5 relative flex items-center border-b-2 border-transparent group-hover:border-b-2 group-hover:border-primary-dark inline-flex transition transition-all ease-in-out duration-200`}
    &::after {
      content: '';
      display: block;
      background-image: url('data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2711.05%27 height=%2710.77%27 viewBox=%270 0 11.05 10.77%27%3E%3Cpath id=%27Icon_awesome-arrow-right%27 data-name=%27Icon awesome-arrow-right%27 d=%27M4.7,3.369l.547-.547a.589.589,0,0,1,.836,0l4.794,4.792a.589.589,0,0,1,0,.836L6.082,13.243a.589.589,0,0,1-.836,0L4.7,12.7a.593.593,0,0,1,.01-.846L7.68,9.019H.592A.59.59,0,0,1,0,8.427V7.638a.59.59,0,0,1,.592-.592H7.68L4.708,4.215A.588.588,0,0,1,4.7,3.369Z%27 transform=%27translate%280 -2.647%29%27 fill=%27%23000d3f%27/%3E%3C/svg%3E%0A');
      width: 12px;
      height: 12px;
      background-repeat: no-repeat;
      margin-left: 8px;
    }
  }
`;
